import { useState } from "react";
import { useSWRConfig } from "swr";
import { useSelector } from "react-redux";
import { cartItemToValidObject } from "../../utils/cart";
import { navigate } from "gatsby";
import useToastify from "../ui/useToastify";
import Api from "../../services/Api";
import useGetCartSummary from "./useGetCartSummary";
import useSetCheckoutIframe from "./useSetCheckoutIframe";
import {
  cartCouponURL,
  cartItemsURL,
  cartURL,
  checkoutURL,
} from "../../ApiEndpoints";
import { cartToCartFormData } from "../../utils/cart";

function useCartOperations() {
  const { mutate: mutateCartSummary } = useGetCartSummary();
  const { setIframe } = useSetCheckoutIframe();

  const [checkoutLoading, setCheckoutLoadingState] = useState(false);

  const { mutate } = useSWRConfig();
  const { user } = useSelector((state) => state.auth);
  const { toastMessage } = useToastify();

  // combine state for loading and updatingTime info...
  const [state, setState] = useState({
    isLoading: false,
    updatingItem: false,
  });

  const cartId = user?.["cart_id"];

  const findRemainingItems = (res, existingRes) => {
    const resIds = new Set(existingRes.map((item) => item.product_id)); // Using a Set for unique product_ids
    return res.filter((item) => !resIds.has(item.product_id));
  };

  const addToCart = async (line_items, resetGridForm, setResponse) => {
    try {
      // show toastMessage while any options is not selected or...
      // req is empty array by the user...
      if (!line_items.length) {
        toastMessage(
          "error",
          "Please ensure that options have been filled in."
        );
      } else {
        setState({ ...state, isLoading: true });
        const data = {
          line_items: line_items,
        };

        const res = await addItemInCart(cartItemsURL(cartId), data);

        if (res?.line_items?.length) {
          const cartItems =
            JSON.parse(window.localStorage.getItem("cartFormItems")) || [];

          // Update local storage with the remaining items not in the response.
          const remainingItems = cartToCartFormData(
            findRemainingItems(res.line_items, cartItems)
          );
          const updatedCartItems = [...cartItems, ...remainingItems];
          localStorage.setItem(
            "cartFormItems",
            JSON.stringify(updatedCartItems)
          );

          // mutate for re-validate cart data after successfully add cart operation...
          mutateCartSummary();
          resetGridForm();
          setResponse(res);
          // console.log(response);
          toastMessage("success", "Item added successfully");

          setState({ ...state, isLoading: false });
        } else {
          setResponse(res);
          setState({ ...state, isLoading: false });
          if (res?.message) {
            toastMessage("error", res?.message.replace(",", "").trim());
          }
        }
        return res;
      }
    } catch (e) {
      console.error(e);
    }
  };

  // empty cart...
  const clearCart = async () => {
    try {
      setState({ ...state, isLoading: true });
      await emptyCart(cartURL(cartId));
      mutate("/cart");
      mutateCartSummary();
      setState({ ...state, isLoading: false });
      window.localStorage.removeItem("cartFormItems");
    } catch (e) {
      console.error(e);
    }
  };

  // function for removing individual cart line-items...
  const removeCartItem = async (sku) => {
    try {
      setCheckoutLoadingState(true);
      setState({ ...state, isLoading: true, removing: sku });

      const req_body = {
        line_items: [{ sku: sku }],
      };
      const res = await removeSingleCartItem(cartItemsURL(cartId), req_body);
      // mutate get cart when line-item removed successfuly...
      if (res?.data?.id) {
        const cartItems =
          JSON.parse(window.localStorage.getItem("cartFormItems")) || [];
        const updatedCartItems = cartItems.filter((item) => item.sku !== sku);
        window.localStorage.setItem(
          "cartFormItems",
          JSON.stringify(updatedCartItems)
        );
        mutate("/cart");
        mutateCartSummary();
        toastMessage("success", "Product removed successfully.");
        setState({ ...state, isLoading: false, removing: "" });
        setCheckoutLoadingState(false);
      } else {
        setState({ ...state, isLoading: false, removing: "" });
        setCheckoutLoadingState(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const removeMultipleCartItems = async (skus) => {
    try {
      if (skus && skus.length) {
        setCheckoutLoadingState(true);
        setState({ ...state, isLoading: true });

        const requested_skus = [];

        skus.forEach((i) => {
          requested_skus.push({ sku: i });
        });

        const req_body = {
          line_items: requested_skus,
        };
        const res = await removeMultipleItems(cartItemsURL(cartId), req_body);
        // mutate get cart when line-item removed successfuly...
        if (res?.data) {
          let cartItems =
            JSON.parse(window.localStorage.getItem("cartFormItems")) || [];
          skus.forEach((sku) => {
            cartItems = cartItems.filter((item) => item.sku !== sku);
          });
          window.localStorage.setItem(
            "cartFormItems",
            JSON.stringify(cartItems)
          );
          mutate("/cart");
          mutateCartSummary();
          toastMessage("success", "Products removed successfully.");
          setState({ ...state, isLoading: false });
          setCheckoutLoadingState(false);
        } else {
          setState({ ...state, isLoading: false });
          setCheckoutLoadingState(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  // function for updating single line item...
  const updateCartItem = async (item, quantity, formik) => {
    try {
      setCheckoutLoadingState(true);
      setState({ updatingItem: item.sku, isLoading: true });

      const res = await updateItem(
        cartItemsURL(cartId),
        cartItemToValidObject(item, quantity)
      );
      // when data is unprocessable...
      // reset to default quantity and return false...
      if (res.status === 422 || res.status === 409) {
        toastMessage("error", res.data.error || res.error);
        setState({ updatingItem: false, isLoading: false });
        await formik.setFieldValue("quantity", item.quantity);
        return false;
      } else if (res && res.data && res.data.id) {
        // setState loading state to false and mutate cart api...
        mutate("/cart");
        toastMessage("success", "Product quantity updated successfully.");
        setState({ updatingItem: false, isLoading: false });
        setCheckoutLoadingState(false);
      } else {
        setState({ updatingItem: false, isLoading: false });
        setCheckoutLoadingState(false);
      }
      return true;
    } catch (e) {
      console.error(e);
    }
  };

  const refreshCheckout = async (setIframeLoading) => {
    try {
      const res = await getCheckoutUrl(checkoutURL(cartId));

      if (res.status === 200) {
        const checkout_url = res.data.checkout_url;

        const gatsbyElement = document.getElementById("___gatsby");
        const iframeElement = document.createElement("iframe");

        iframeElement.src = checkout_url;
        iframeElement.id = "checkout-iframe";
        iframeElement.style.display = "block";
        iframeElement.style.width = "100%";
        iframeElement.style.minHeight = "100vh";
        iframeElement.style.overflow = "auto";

        iframeElement.addEventListener("load", () => {});
        gatsbyElement.appendChild(iframeElement);

        // set loading indicator to false after iframe load.
        setIframeLoading(false);
      } else {
        navigate("/cart");
        toastMessage("error", "Something went wrong. please try again");
      }
    } catch (e) {
      console.error(e);
    }
  };

  // for applying coupon code to the cart...
  const applyCouponCode = async (code) => {
    try {
      setState({ ...state, isLoading: true });

      const res = await applyCoupon(cartCouponURL(cartId), {
        coupon_code: code,
      });
      if (res?.data) {
        toastMessage("success", "Coupon code applied successfully.");
        mutate("/cart");
      }
      // If any error occurred then show bc error to user...
      if (res?.message?.status && res?.message?.title.includes("not valid")) {
        toastMessage("error", res.message.title);
      }
      setState({ ...state, isLoading: false });
    } catch (e) {
      console.error(e);
    }
  };

  // removing coupon code from the existing cart...
  const removeCouponCode = async (code) => {
    try {
      setState({ ...state, isLoading: true });

      const res = await removeCoupon(cartCouponURL(cartId), {
        coupon_code: code,
      });

      setState({ ...state, isLoading: false });
      if (res?.data) {
        toastMessage("success", "Coupon code removed successfully.");
        mutate("/cart");
      }
    } catch (e) {
      console.error(e);
    }
  };

  return {
    state,
    checkoutLoading,
    setCheckoutLoadingState,
    addToCart,
    clearCart,
    removeCartItem,
    updateCartItem,
    applyCouponCode,
    removeCouponCode,
    removeMultipleCartItems,
    refreshCheckout,
  };
}

async function addItemInCart(url, data) {
  const response = await Api.post(url, data);
  if (response?.status === 200) {
    return response?.data?.data;
  } else {
    return { message: response?.response?.data?.message };
  }
}

async function getCheckoutUrl(url) {
  const response = await Api.post(url);
  return response;
}

async function emptyCart(url) {
  const response = await Api.delete(url);
  return response?.data;
}

async function removeSingleCartItem(url, data) {
  const response = await Api.delete(url, data);
  return response?.data;
}

async function removeMultipleItems(url, data) {
  const response = await Api.delete(url, data);
  return response?.data;
}

async function updateItem(url, data) {
  const response = await Api.put(url, data);
  if (response?.status === 200) {
    return response?.data;
  } else {
    return response?.response?.data;
  }
}

async function applyCoupon(url, data) {
  const response = await Api.post(url, data);
  return response?.data;
}

async function removeCoupon(url, data) {
  const response = await Api.delete(url, data);
  return response?.data;
}
export default useCartOperations;
